$(document).on('click', '.qr-code-copy-link', function () {
    var input = document.getElementById("qr-code-url");
    var aux = document.createElement("div");
    aux.setAttribute("contentEditable", true);
    aux.innerHTML = input.innerHTML;
    console.log(input);
    console.log(input.innerHTML);
    aux.setAttribute("onfocus", "document.execCommand('selectAll',false,null)");
    document.body.appendChild(aux);
    aux.focus();
    document.execCommand("copy");
    document.body.removeChild(aux);
});
