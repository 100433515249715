$(document).on("click", ".move-li-link", function (e) {
    var $this = $(this);

    var FolderId = $this.attr("folder_id");
    var Url = $this.attr("url");

    if ($this.hasClass('clicked')) {
        $this.removeClass('clicked');
        $this.find(".folder-name-js").removeClass('font-bold');

        $.ajax({
            url: Url,
            dataType: "js",
            complete: function(data) {
                $(".application-offcanvas-js .content").html(data.responseText);
            }
        });

    } else {
        $this.addClass('clicked');
        setTimeout(function () {
            if ($this.hasClass('clicked')) {
                $(".move-li-link").find(".folder-name-js").removeClass("font-bold");
                $this.removeClass('clicked');
                $this.find(".folder-name-js").addClass("font-bold");
                $("#selected-folder-id").val(FolderId);
            }
        }, 500);
    }
    e.stopPropagation();
});
