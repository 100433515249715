/*
<ul class="tabs">
    <li><a id="tab1" class="active">test1</a></li>
    <li><a id="tab2" class="inactive">test2</a></li>
    <li><a id="tab3" class="inactive"">test3</a></li>
    <li><a id="tab4" class="inactive"">test4</a></li>
</ul>
<div class="tabs-content">
    <div class="tabs-panel" id="tab1C" style="display: block;">1Some content</div>
    <div class="tabs-panel" id="tab2C" style="display: none;">2Some content</div>
    <div class="tabs-panel" id="tab3C" style="display: none;">3Some content</div>
    <div class="tabs-panel" id="tab4C" style="display: none;">4Some content</div>
</div>
*/

$(document).ready(function () {
    $('.tabs a:not(:first)').addClass('inactive');
    $('.tabs-content .tabs-panel').hide();
    $('.tabs-content .tabs-panel:first').show();

    $(document).on('click', '.tabs a', function () {

        var t = $(this).attr('id');
        var tab = $(this).closest(".tabs");
        var dataActiveClasses = tab.attr('data-active-classes');
        var dataInactiveClasses = tab.attr('data-inactive-classes');
        if ($(this).hasClass('active') == false) { //this is the start of our condition
            var links = tab.find('a');
            links.removeClass(dataActiveClasses);
            links.removeClass(dataInactiveClasses);

            $(this).addClass(dataActiveClasses);

            $('.tabs-content .tabs-panel').hide();
            $('#' + t + 'C').fadeIn('slow');
        }
    });

});