// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import "trix"
import "@rails/actiontext"
import "preline/dist/hs-ui.bundle"
import "./jquery"
import "./contextmenu"
import "./clipboard"
import "./views/documents/_form"
import "./views/shared_documents/index"
import "./views/documents/_folder_context_menu"
import "./views/folder_sharing/show"
import "./views/moves/new"
import "./views/details/_favorite"
import "./views/orders/_form"
import "./views/qr_codes/_form"
import "./views/badges/_form"
import "./usefull_functions"
import "./jquery_tailwind"
import "@nathanvda/cocoon"
import Rails from '@rails/ujs'
import selectize from 'selectize'
import setTimezoneCookie from './user_agent_timezone/index'

Rails.start()