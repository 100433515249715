$.ajaxSetup({
    success: function (jqXHR, textStatus, errorThrown) {
        if (jqXHR.status === 404) {
            alert("Element not found.");
        } else {
            alert("Error: " + textStatus + ": " + errorThrown);
        }
    }
});

$(document).on('ajax:success', ".display-in-target-container-js", function (event) {
    var detail = event.detail;
    var data = detail[0].body.innerHTML, status = detail[1], xhr = detail[2];
    $($(this).attr("data-remote-target")).html(data);
});

$(document).on('ajax:error', ".display-in-target-container-on-error-js", function (event) {
    var detail = event.detail;
    var data = detail[0].body.innerHTML, status = detail[1], xhr = detail[2];

    $($(this).attr("data-remote-target")).html(data);
});


$(document).on('ajax:success', ".display-in-modal-js", function (event) {
    var detail = event.detail;
    var data = detail[0].body.innerHTML, status = detail[1], xhr = detail[2];
    $(".modal-container-js").removeClass("invisible");
    $(".modal-container-js .modal-container-body-js").html(data);
});

$(document).on('ajax:success', ".refresh-current-page-js", function (event) {
    location.reload(true);
});

$(document).on('ajax:success', ".display-in-offcanvas-js", function (event) {
    var detail = event.detail;
    var data = detail[0].body.innerHTML, status = detail[1], xhr = detail[2];
    $(".application-offcanvas-js .content").html(data);
});
