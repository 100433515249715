$(document).on("click", ".search-offcanvas-link", function () {
    $(".search-offcanvas").removeClass("invisible");
});

$(document).on("mouseup", ".offcanvas",function(e){
    var container = $(".offcanvas-body");
    // if the target of the click isn't the container nor a descendant of the container
    if (!container.is(e.target) && container.parent(".offcanvas").hasClass("invisible") === false && container.has(e.target).length === 0){
        container.closest(".closeable").addClass("invisible");
    }
});