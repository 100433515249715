$(document).on("click", ".modal-wrapper-toggle-link-js", function () {
    if ($(".modal-wrapper-js").hasClass("invisible")) {
        $(".modal-wrapper-js").removeClass("invisible");
    } else {
        $(".modal-wrapper-js").addClass("invisible");
    }
});

$(document).on("mouseup", ".modal-wrapper-container-js", function(e){
    var container = $(".modal-wrapper-body-js");
    // if the target of the click isn't the container nor a descendant of the container
    if (!container.is(e.target) && container.has(e.target).length === 0){
        container.find(".modal-wrapper-toggle-link-js").trigger("click");
    }
});

$(document).on('click', ".modal-container-close-js", function (event) {
    $(".modal-container-js").addClass("invisible");
});
