$(document).on("keyup paste", "#badge_title", function() {
   $("#badge-title-js").html($(this).val());
});

$(document).on("keyup paste", "#badge_reference", function() {
   $("#badge-reference-js").html($(this).val());
});

$(document).on("keyup paste", "#badge_address", function() {
   $("#badge-address-js").html($(this).val());
});

$(document).on("keyup paste", "#badge_powered_by", function() {
   $("#badge-powered-by-js").html($(this).val());
});