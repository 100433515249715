var doubleClicked = false;

$(document).on("contextmenu", ".context-menu-target", function (e) {
    var dropDown = $(this).find(".document-dropdown-menu-wrapper");

    hideConextMenus();

    RelocateMenu(e, dropDown, doubleClicked);
    if ($(dropDown).hasClass("invisible")) {
        dropDown.removeClass("invisible");
    } else {
        dropDown.addClass("invisible");
    }
    e.preventDefault(); // To prevent the default context menu.
    return false;
});

$(document).on("click", "#folder-container", function (e) {
        hideConextMenus();
});

$(document).on("contextmenu", "#folder-container", function (e) {
    var dropDown = $(".folder-dropdown-menu-wrapper");
    hideConextMenus();
    RelocateMenu(e, dropDown, doubleClicked);

    if ($(dropDown).hasClass("invisible")) {
        dropDown.removeClass("invisible");
    } else {
        dropDown.addClass("invisible");
    }
    e.preventDefault(); // To prevent the default context menu.
    return false;
});

function hideConextMenus() {
    $(".document-dropdown-menu-wrapper").addClass("invisible");
    $(".folder-dropdown-menu-wrapper").addClass("invisible");
}

function RelocateMenu(e, dropDown, doubleClicked) {
    if(doubleClicked == false) {
        var windowHeight = $(window).height()/2;
        var windowWidth = $(window).width()/2;
        //When user click on bottom-left part of window
        if(e.clientY > windowHeight && e.clientX <= windowWidth) {
            dropDown.css("left", e.clientX);
            dropDown.css("bottom", $(window).height()-e.clientY);
            dropDown.css("right", "auto");
            dropDown.css("top", "auto");
        } else if(e.clientY > windowHeight && e.clientX > windowWidth) {
            //When user click on bottom-right part of window
            dropDown.css("right", $(window).width()-e.clientX);
            dropDown.css("bottom", $(window).height()-e.clientY);
            dropDown.css("left", "auto");
            dropDown.css("top", "auto");
        } else if(e.clientY <= windowHeight && e.clientX <= windowWidth) {
            //When user click on top-left part of window
            dropDown.css("left", e.clientX);
            dropDown.css("top", e.clientY);
            dropDown.css("right", "auto");
            dropDown.css("bottom", "auto");
        } else {
            //When user click on top-right part of window
            dropDown.css("right", $(window).width()-e.clientX);
            dropDown.css("top", e.clientY);
            dropDown.css("left", "auto");
            dropDown.css("bottom", "auto");
        }
        dropDown.show();
        doubleClicked = true;
    } else {
        e.preventDefault();
        doubleClicked = false;
        dropDown.hide();
    }
}