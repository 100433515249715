$(document).on("change", "#order_client_id", function () {
    var ClientId = $("#order_client_id").find(":selected").val();

    $("#order_admin_client_user_id").find('option').not(':first').remove();

    if (ClientId === "") {
        $("#order_admin_client_user_id").attr('disabled', 'disabled');
    } else {
        $.ajax({
            url: "/users?search[role_code]=client_admin&search[client_id]=" + ClientId,
            type: "GET",
            error: function () {
                callback();
            },
            success: function (res) {
                for (var i = 0, len = res.records.length; i < len; ++i) {
                    $("#order_admin_client_user_id").append('<option value="' + res.records[i]['id'] + '">' + res.records[i]['label'] + '</option>');
                }
                $("#order_admin_client_user_id").prop("disabled", false);
            },
        });
    }
});