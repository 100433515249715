$(document).on('ajax:success', ".hide-or-show-badge-tab-js", function (event) {
    setTimeout(
        function()
        {
            var BadgeButton = $("#badge-tab-button-js");
            if ($('#record-qr-code-image-container-js').length) {
                BadgeButton.removeClass("hidden");
            } else {
                BadgeButton.addClass("hidden");
            }
        }, 1500);

});

$(document).on('ajax:error', ".hide-or-show-badge-tab-js", function (event) {
    Turbo.visit(window.location.href);
})
    