import { Controller } from "@hotwired/stimulus"
import Dropzone from "dropzone"
import 'dropzone/dist/dropzone.css'
import 'dropzone/dist/basic.css'
import { DirectUpload } from "@rails/activestorage"
import jquery from "jquery"

export default class extends Controller {
  static targets = ["input"]

  connect() {
    Dropzone.autoDiscover = false
    this.inputTarget.disable = true
    this.inputTarget.style.display = "none"
    const dropzone = new Dropzone(this.element, {
      url: this.inputTarget.getAttribute('data-direct-upload-url'),
      maxFilesize: '1000',
      autoQueue: false,
      dictDefaultMessage: ""
      // disablePreviews: true
    })

    dropzone.on("addedfile", file => {
      setTimeout(() => {
        if (file.accepted) {
          const upload = new DirectUpload(file, this.url)
          upload.create((error, blob) => {
            this.hiddenInput = document.createElement("input")
            this.hiddenInput.type = "hidden"
            this.hiddenInput.className = "dropzone-doc-input"
            this.hiddenInput.name = this.inputTarget.name
            if (typeof file.fullPath !== 'undefined') {
              this.hiddenInput.value = `${file.fullPath}/${blob.signed_id}`;
            } else {
              this.hiddenInput.value = `${blob.filename}/${blob.signed_id}`;
            }
            
            this.inputTarget.parentNode.insertBefore(this.hiddenInput, this.inputTarget.nextSibling)
            dropzone.emit("success", file)
            dropzone.emit("complete", file)
            
          })
        }
      }, 500)
    })

    dropzone.on("complete", function (file) {
      if (this.getAddedFiles().length === $(".dropzone-doc-input").length) {
        jQuery('#upload-folder-form-dropzone').submit();
      }
    })
  }

  get url() {
    return this.inputTarget.getAttribute('data-direct-upload-url');
  }
}