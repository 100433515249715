$(document).on("click", ".dropdown-menu-wrapper button, .dropdown-menu-wrapper a", function () {
    var dropDown = $(this).parent().next(".absolute").first();

    // Hide all dropdown
    $(".dropdown-menu-wrapper div[role='menu']").removeClass("current");

    if ($(dropDown).hasClass("invisible")) {
        dropDown.removeClass("invisible");
        dropDown.addClass("current");
    } else {
        dropDown.addClass("invisible");
    }

    // Hide all dropdown
    $(".dropdown-menu-wrapper div[role='menu']:not(.current)").addClass("invisible");
});
